
import { Vue, Options } from "vue-class-component";

@Options({
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    }
  }
}) export default class Acknowledgement extends Vue {}
