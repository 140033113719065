const userDataOptom = {
  correct: 0,
  wrong: 0,
  difficulties: {
    easy: {correct: 0, wrong: 0},
    medium: {correct: 0, wrong: 0},
    hard: {correct: 0, wrong: 0}
  },
  categories: {
    'Ocular Abnormalities': {correct: 0, wrong: 0},
    'Children and Special Needs': {correct: 0, wrong: 0},
    'Electrophysiology': {correct: 0, wrong: 0},
    'Dispensing': {correct: 0, wrong: 0},
    'Binocular Vision': {correct: 0, wrong: 0},
    'Low Vision': {correct: 0, wrong: 0},
  },
}

export const userDataTemplate = userDataOptom;