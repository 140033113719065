
import { Options, Vue } from 'vue-class-component';
import store from '../store';
import { userDataTemplate } from '../models/userDataTemplate'

@Options({
  data() {
    return {
      question: '',
      answers: [],
      correctAnswer: '',
      scored: false,
      showAnswer: false,
      hasAnswered: false,
      activeIndex: -1,
      category: '',
      difficulty: '',
      showOptions: false,
      categories: Object.keys(userDataTemplate.categories)
    };
  },
  async created() {
    await this.nextQuestion();
  },
  methods: {
    submit(answer: string, index: number) {
      if (answer === this.correctAnswer) {
        this.scored = true;
        store.dispatch('increaseCorrect', {
          difficulty: this.difficulty,
          category: this.category
        });
      } else {
        this.scored = false;
        store.dispatch('increaseWrong', {
          difficulty: this.difficulty,
          category: this.category
        });
      }

      this.activeIndex = index;
      this.hasAnswered = true;
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    onCategorySelect(event: any) {
      const selectedCategory = event.target.value;
      store.commit('setSelectedCategory', selectedCategory)
      this.nextQuestion()
    },
    async nextQuestion() {
      this.showAnswer = false;
      this.hasAnswered = false;
      this.scored = false;
      this.activeIndex = -1;

      let targetURL = process.env.VUE_APP_API_URL

      if (store.state.selectedCategory) {
        targetURL += `?category=${store.state.selectedCategory.replaceAll(' ', '_')}`
      }

      const response = await fetch(targetURL);
      const data = await response.json();
      this.question = this.replaceEncodedCharacters(data.results[0].question);
      const temporaryAnswers = data.results[0].incorrect_answers;
      temporaryAnswers.splice(
        Math.round(Math.random() * 1000) % 4,
        0,
        data.results[0].correct_answer
      );
      this.correctAnswer = this.replaceEncodedCharacters(data.results[0].correct_answer);
      let counter = 0;
      this.answers = temporaryAnswers.map((answer: any) => ({
        index: counter++,
        answer: this.replaceEncodedCharacters(answer),
      }));
      this.category = this.replaceEncodedCharacters(data.results[0].category);
      this.difficulty = data.results[0].difficulty;
    },
    replaceEncodedCharacters(text: string) {
      return text.replaceAll('&quot;', '"')
        .replaceAll('&amp;', '&')
        .replaceAll('&#039;', '\'')
        .replaceAll('&reg;', '®')
        .replaceAll('&trade;', '™')
        .replaceAll('&eacute;', 'é')
        .replaceAll('&uuml;', 'ü')
        .replaceAll('&rsquo;', '’')
        .replaceAll('&deg;', '°')
    },
  },
})
export default class Game extends Vue {}
